import React from 'react';
import { useSpring, animated } from 'react-spring';


export default function ColorBar() {
    const props = useSpring({
        opacity: 1, 
        marginTop: 0, 
        from: {opacity: 0, marginTop: -1000}, 
        config: {
            delay: 1000, 
            duration: 1000,
            tension: 180,
            friction: 12
        }
    })

    return <animated.div className='ColorBar' style={props}>
        <section className="ColorBarContent">
            <p className='Intro'>Hi,</p>
            <p className='Intro'>I'm Ash.</p>
            <p className='Title'>A Full Stack Engineer</p>
        </section>
    </animated.div>
}

