import React from 'react';
import ReactGA from 'react-ga';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ColorBar from './components/ColorBar';
import Bio from './components/Bio'
import './App.css';

ReactGA.initialize('UA-181041898-1');
ReactGA.pageview('/homepage');

function App() {
  return (
    <Container className="h-100">
        <Row className="h-100">

          <Col>
            <ColorBar />
          </Col>

          <Col>
            <Bio />
          </Col>

        </Row>
    </Container>
  );
}

export default App;
