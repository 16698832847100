import React from 'react';
import { useSpring, animated } from 'react-spring';
import photo from '../img/ash_reno.jpg';
import { FaLinkedinIn } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";



export default function ColorBar() {
    const props = useSpring({opacity: 1, from: {opacity: 0}, config: {delay: 1000, duration: 1000}})

    return <animated.div className='Bio' style={props}>
        <article className="Bio">
            <figure style={{'textAlign': 'center', 'marginBottom': '2em'}}>
                <img src={photo} alt="Ash Reno" style={{'borderRadius': '50%', 'maxHeight': '250px'}}></img>
            </figure>

            <p>I'm a full stack engineer building business management software. I work on tools that help with things like inventory tracking, forecasting, routing deliveries, self-checkout systems, and more. I believe in high-quality code that's easy to scale, cultivating feedback loops, and caffeine.</p>
            <p>In my spare time, I'm probably building another totally necessary mechanical keyboard, cry-laughing at the latest stand-up on Netflix, or getting lost somewhere adventuring in Azeroth.</p>

            <section>
                <a href='mailto:ashreno.dev@gmail.com'><MdEmail /></a> | <a href='https://www.linkedin.com/in/ashreno/'><FaLinkedinIn /></a>
            </section>
        </article>
    </animated.div>
}
